canvas {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.fullscreen {
	width: 100%;
	height: 100%;
	padding:20px;
	overflow-y: scroll;
}
