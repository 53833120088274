.imageeditormodal {
	background-color: #333333;
}

.imageeditormodal .modal-dialog {
	width: 100%;
	height: 100%;
	padding: 0;
	margin:0;
	background-color: #333333;
}
.imageeditormodal .modal-content {   
	height: 100%;
	border-radius: 0;
	overflow:auto;
	background-color: #333333;
}

.imageeditor {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.imageeditor #spinwheel {
	z-index:100; 
	position: fixed; 
	left:50%; 
	top: 50%; 
	font-size: 30px; 
	text-align:center;  
	transform: translateX(-50%) translateY(-50%); 
	background-color: #111111; 
	color:white; 
	border-radius: 6px; 
	padding: 5px 20px; 
	opacity: 1.0;
	transition: opacity 0.3s;
}
.imageeditor #tooltip {
	z-index:100; 
	position: fixed; 
	left:50%; 
	bottom: -100px; 
	font-size: 16px; 
	text-align:center;  
	transform: translateX(-50%); 
	background-color: #111111; 
	color:white; 
	border-radius: 6px; 
	padding: 5px 20px; 
	transition: all 1s;
}
.mirrorimage {
	transform: scaleX(-1);
}
/* Imageeditable */
.editableimage {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
[data-ng-drop].drag-enter .editableimage{
    border: solid 5px red;
}
.editableimage-droparea {
	background-color: #eeeeee; 
	border: 2px dashed #aaaaaa; 
	border-radius: 5px; 
	margin: 0 auto; 
	text-align: center; 
	padding: 35px;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.editableimage-droparea.dragover {
	background-color: #fbffc4; 
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
	cursor: copy;
}
.editableimage-droparea.nodrop {
	cursor: no-drop;
}

.editableimage-thumbnail {
	/*
	background-color: #eeeeee; 
	border: thin solid #cccccc; 
	border-radius: 5px; */
	
	margin: 0 auto; 
	text-align: center; 
}
@media (min-width: 992px) { 
.editableimage-thumbnail div {
	background-color: rgba(80,80,80,0.0);
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
	opacity: 0.0;
}
.editableimage-thumbnail div:hover  {
	background-color: rgba(80,80,80,0.4);
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
	opacity: 1.0;
}
}
.editableimage-caption {
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
	margin-bottom: 5px;
}
.editableimage-caption:hover {
	cursor: hand;
}

/* End imageeditable */
