/* catobjects editor */
.catobjectseditor .sidebar {
}
.catobjectseditor .catobjectcategory {
	padding: 8px 5px 5px;
	border-collapse: collapse;
	border-left: 5px solid white;
	transition: all 200ms;
	border-bottom: thin solid #dddddd;
}
.catobjectseditor .catobjectcategory:first-child {
	margin-top: 15px;
}
.catobjectseditor .catobjectcategory:hover {
	background-color: #e2e2e2;
	transition: all 200ms;
	cursor: pointer;
}
.catobjectseditor .backdrop {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.1);
	z-index: -1;
}
.catobjectseditor data-ng-form * {
	z-index: 10 !important;
}
.catobjectseditor .ta-toolbar, .catobjectseditor .btn-group {
	z-index: 100 !important;
}
.catobjectseditor .dropdown-menu {
	z-index: 100 !important;
}
@media (min-width: 768px) {
	.catobjectseditor .catobjectcategory .editcatobjectcategory {
		display: none;
	}
	.catobjectseditor .catobjectcategory:hover .editcatobjectcategory {
		display: block;
	}
}
.catobjectseditor .catobjectcategory.active {
	border-left-width: 10px;
	transition: all 200ms;
	font-weight: bold;
}
.catobjectseditor .catobjectcategory.inedit {
	background-color: white;
	border-left-width: 0px;
	transition: all 0s;
	padding: 20px;
	color: black;
	border: thin solid grey;
}

.catobjectseditor .catobjectitem {
	padding: 8px 5px 5px;
	border-collapse: collapse;
	border-top: thin solid lightgray;
	transition: all 200ms;
}
.catobjectseditor .catobjectitem:first-child {
	border-top: none;
	margin-top: 15px;
}
.catobjectseditor .catobjectitem span {
	font-size: 12px;
	color: black;
}
.catobjectseditor .catobjectdescriptionitem {
	padding: 5px 5px 5px 0;
	border-collapse: collapse;
	transition: all 200ms;
	max-width: 100%;
	overflow: hidden;
}

.catobjectseditor .catobjectdescriptionitem span {
	font-size: 12px;
	color: black;
}
.catobjectseditor .catobjectdescriptionitem .content {
	font-size: 12px;
	color: black;
	margin-bottom: 5px;
	border-bottom: thin solid lightgray;
}
.catobjectseditor .catobjectdescriptionitem .content:hover {
	cursor: pointer;
	color: #888888;
	transition: all 200ms;
}

.catobjectseditor .catobjectitem:hover {
	cursor: pointer;
	color: #000000;
	transition: all 200ms;
}
.catobjectseditor .catobjectitem .inedit {
	background-color: white;
	border-left-width: 0px;
	transition: all 200ms;
	padding: 20px;
	box-shadow: 0px 0px 10px 0px darkgray inset;
}

.catobjectseditor .catobjectitem div.descriptions {
	background-color: white;
	border-left-width: 0px;
	transition: all 200ms;
	padding: 20px;
	box-shadow: 0px 0px 10px 0px lightgray  inset;
}

.catobjectseditor .catobjectitem div.sheet {
	margin-top: 20px;
	margin-bottom: 20px;
	transition: all 500ms;
	text-align: center;
}

.catobjectseditor .catobjectitem div.sheet:hover {
	transition: all 500ms;
	color: #333333;
	-webkit-transform: scale(1.05,1.05);
	cursor: pointer;
	
}
.catobjectseditor .catobjectitem div.sheet a {
	text-decoration: none;
	color: black;
}

/* catobjects array */
.catobjectsarray .catobjectsarrayitem, .catobjectslist .catobjectslistitem {
	padding: 8px 0px;
	border-collapse: collapse;
	border-top: thin solid lightgray;
	transition: all 200ms;
}
.catobjectsarray .catobjectsarrayitem:first-child, .catobjectslist .catobjectslistitem:first-child {
	border-top: none;
	margin-top: 5px;
}
.catobjectsarray .catobjectsarrayitem span {
	cursor: pointer;
	font-size: 12px;
	color: black;
}
.catobjectsarray .catobjectsarrayitem span:hover {
	color: #888888;
	transition: all 200ms;
}
.catobjectsarray .catobjectsarrayitem .content, .catobjectslist .catobjectslistitem .content{
	margin-bottom: 5px;
	border-bottom: thin solid lightgray;
	font-size: 12px;
	color: black;
}

.catobjectsarray .catobjectsarrayitem .content:hover {
	cursor: pointer;
	color: #888888;
	transition: all 200ms;
}

.catobjectsarray .catobjectsarrayitem.inedit {
	background-color: white;
	border-left-width: 0px;
	transition: all 200ms;
	padding: 20px;
	box-shadow: 0px 0px 10px 0px darkgray inset;
}
.catobjectsarray .catobjectsarrayitem img, .catobjectslist .catobjectslistitem img {
	max-width: 100%;
}
.catobjectsarray .risklevel, .catobjectspicker .risklevel {
	float: left;
	min-height: 45px;
	min-width: 43px;
	padding: 1px 0;
	margin-right: 10px;
}

.catobjectspicker .list, .catobjectsdescriptionspicker .list {
	max-height: 300px;
	overflow-y: scroll;
}

.catobjectspicker .description {
	padding-left: 15px;
	font-style: italic;
}
.catobjectspicker .list img, .catobjectsdescriptionspicker .list img {
	max-width: 100%;
}