.colortile {
	min-width: 40px;
	min-height: 20px;
	border: thin solid lightgrey;
	margin-left: 1px;
	margin-bottom: 1px;
}
.colorspan:first-child {
	margin-top: 3px;
}
.colorspan:last-child {
	margin-bottom: 3px;
}
.colortile:first-child {
	margin-left: 5px;
}
.colortile:last-child {
	margin-right: 5px;
}
