.faqs h4:hover {
	color: #666666;
}
.faqs .faqcategory {
	min-width: 15px;
	min-height: 20px;
	float: left;
	margin-right: 10px;
}
.faqs .faq {
	margin-left: 25px;
}

.tutorials h4:hover {
	color: #666666;
}
.tutorials .tutorialcategory {
	min-width: 15px;
	min-height: 20px;
	float: left;
	margin-right: 10px;
}
.tutorials .tutorial {
	margin-left: 25px;
}
.instantsupport {
	position: fixed;
	bottom:0;
	right:0;
	z-index: 1000;
}
.modalsupport {
	max-height: 350px;
	overflow-y: scroll;
}
.statusprogressbar, .subnavigation, .ordernavigation {
	margin-bottom: 20px;
}
.subnavigation, .ordernavigation {
	margin-top: 20px;
}
.subnavigation .navsection {
	float: left;
	min-height: 150px;
}
.ordernavigation .navsection {
	float: left;
}

.subnavigation .navsection .divider, .ordernavigation .navsection .divider {
	float: left;
	min-height: 45px;
	min-width: 27px;
	padding: 22px 0;
}
.subnavigation .navsection.active .divider, .ordernavigation .navsection.active .divider {
	min-width: 25px;
}
.subnavigation .navsection .divider div, .ordernavigation .navsection .divider div {
	min-height: 1px;
	min-width: 100%;
}

.subnavigation .navsection .divider.blu div, .ordernavigation .navsection .divider.blu div{
	background-color: #35b5e1;
}
.subnavigation .navsection .divider.rosso div {
	background-color: #de5152;
}
.subnavigation .navsection .num, .ordernavigation .navsection .num{
	float: left;
	min-height: 45px;
	min-width: 43px;
	padding: 1px 0;
}
.subnavigation .navsection .num>div, .ordernavigation .navsection .num>div{
}
.subnavigation .navsection .num .title, .ordernavigation .navsection .num .title{
	max-width: 45px;
	font-size: 10px;
	text-align: center;
}
.subnavigation .navsection.active .num .title, .ordernavigation .navsection.active .num .title{
	font-weight: bold;	
}
.subnavigation .navsection .num .title.blu, .ordernavigation .navsection .num .title.blu{
	color: #35b5e1;
}
.subnavigation .navsection .num .title.rosso {
	color: #de5152;
}
.subnavigation .navsection.active .num, .ordernavigation .navsection.active .num{
	min-width: 45px;
	padding: 0;
}
.subnavigation .navsection .num>div:hover {
	transform: scale(1.1);
}

.statusmessagebar div div.mark {
	width: 22px;
	text-align: center;
	float: left;
	margin: 0 auto;
	background-color: transparent;
}
.angular-google-map-container { 
	height: 400px; 
}

.hospitalrows {
	padding-top: 5px;
	padding-bottom: 5px;
}

.hospitalrows:hover {
	background-color: #eeeeee;
}

.costdiv {
	font-size: 14px;
}
.costrow {
	font-size: 13px;
	color: gray;
}
.costname:hover {
	color: gray;
	cursor: pointer;
}

.allegeddoceditormodal {
	background-color: #333333;
}

.allegeddoceditormodal .modal-dialog {
	width: 100%;
	height: 100%;
	padding: 0;
	margin:0;
	background-color: #333333;
}
.allegeddoceditormodal .modal-content {   
	height: 100%;
	border-radius: 0;
	overflow:auto;
	background-color: #333333;
}

.allegeddoceditor {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.allegeddoceditor #spinwheel {
	z-index:100; 
	position: fixed; 
	left:50%; 
	top: 50%; 
	font-size: 30px; 
	text-align:center;  
	transform: translateX(-50%) translateY(-50%); 
	background-color: #111111; 
	color:white; 
	border-radius: 6px; 
	padding: 5px 20px; 
	opacity: 1.0;
	transition: opacity 0.3s;
}

.allegeddocedit {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
[data-ng-drop].drag-enter .allegedoc{
    border: solid 5px red;
}
.allegeddocedit-droparea {
	background-color: #eeeeee; 
	border: 2px dashed #aaaaaa; 
	border-radius: 5px; 
	margin: 0 auto; 
	text-align: center; 
	padding: 15px;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.allegeddocedit-droparea.dragover {
	background-color: #fbffc4; 
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
	cursor: copy;
}
.allegeddocedit-droparea.nodrop {
	cursor: no-drop;
}

.allegeddocedit-thumbnail {	
	margin: 0 auto; 
	text-align: center;
	background-position: center; 
	background-color: #eeeeee; 
	border: 2px solid #aaaaaa; 
	border-radius: 5px; 
	margin-bottom: 5px;
}
.allegeddocedit-thumbnail .prev {
	position: absolute;
	height: 100%;
	width: 50%;
	left: 0px;
	top: 0px;
	text-align: left;
	display: hidden;
} 
.allegeddocedit-thumbnail .next {
	position: absolute;
	height: 100%;
	width: 50%;
	right: 0px;
	top: 0px;
	text-align: right;
	display: hidden;
} 
.allegeddocedit-thumbnail:hover .perv, .allegeddocedit-thumbnail:hover .next {
	display: block;
}
.allegeddocviewermodal {
	background-color: #333333;
}

.allegeddocviewermodal .modal-dialog {
	width: 100%;
	height: 100%;
	padding: 0;
	margin:0;
	background-color: #333333;
}
.allegeddocviewermodal .modal-content {   
	height: 100%;
	border-radius: 0;
	overflow:auto;
	background-color: #333333;
}

.allegeddocviewer {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.allegeddocviewer-thumbnail {	
	margin: 0 auto; 
	text-align: center;
	background-position: center; 
}

@media (min-width: 992px) { 
	.allegeddocedit-thumbnail div {
		-webkit-transition: all 0.5s;
		-moz-transition: all 0.5s;
		transition: all 0.5s;
		opacity: 0.0;
	}
	.allegeddocedit-thumbnail div:hover  {
		-webkit-transition: all 0.5s;
		-moz-transition: all 0.5s;
		transition: all 0.5s;
		opacity: 1.0;
	}
}
.allegeddocedit-caption {
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
	margin-bottom: 5px;
}
.allegeddocedit-caption:hover {
	cursor: hand;
}

.dailymenatworkinedit {
	padding: 30px !important;
	background-color: white;
	border-left-width: 0px;
	transition: all 200ms;
	padding: 20px;
	box-shadow: 0px 0px 10px 0px darkgray inset;
}
.dailymenatworkwork:hover {
	cursor: pointer;
	color: darkgrey;
}
.bottombordered {
	border-bottom: thin solid lightgray;
}
