/* Date picker */
.uib-title, .uib-right, .uib-left {
	border: none !important;
}
.uib-close, .uib-datepicker-current, .uib-clear {
	color: #333 !important;
	background-color: #fff  !important;
	border-color: #ccc  !important;
}
.uib-day button, .uib-month button, .uib-year button {
	border: none  !important;
}
